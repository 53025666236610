import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import ImageWrap from 'components/ImageWrap'
import { StaticImage } from 'gatsby-plugin-image'
import { capitalize } from 'js/utils'

export default function IndustriesTemplate({ data }) {
  const page = data.wpPage
  const { post } = page.template.platformSingle

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-26533 page-child parent-pageid-135 amp-analytics app-data index-data singular-data page-data page-26533-data page-amp-analytics-data page-builder-data'
      page={page}
    >
      <section className='textarea'>
        <div className='textarea__container'>
          <div className='textarea__text'>
            {post && <Post content={post} />}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_PlatformSingle {
          platformSingle {
            post {
              ... on WpTemplate_PlatformSingle_Platformsingle_Post_TextFull {
                fieldGroupName
                title
                body
              }
              ... on WpTemplate_PlatformSingle_Platformsingle_Post_TextColumns {
                fieldGroupName
                title
                body
              }
              ... on WpTemplate_PlatformSingle_Platformsingle_Post_TextCube {
                fieldGroupName
                title
                body
              }
              ... on WpTemplate_PlatformSingle_Platformsingle_Post_IconColumns {
                fieldGroupName
                columns {
                  icon {
                    ...GatsbyImage
                  }
                  title
                  body
                }
              }
              ... on WpTemplate_PlatformSingle_Platformsingle_Post_BrandText {
                fieldGroupName
                icon {
                  ...GatsbyImage
                }
                pretitle
                title
                body
              }
            }
          }
        }
      }
    }
  }
`

const Post = ({ content }) => {
  return (
    <div>
      {content &&
        content.map((block, i) => {
          const colorClass = i % 2 === 1 ? 'light' : 'dark'
          if (
            block.fieldGroupName ===
            'Template_PlatformSingle_Platformsingle_Post_TextFull'
          ) {
            return (
              <PostTextFull key={i} colorClass={colorClass} content={block} />
            )
          }
          if (
            block.fieldGroupName ===
            'Template_PlatformSingle_Platformsingle_Post_TextColumns'
          ) {
            return (
              <PostTextColumns
                key={i}
                colorClass={colorClass}
                content={block}
              />
            )
          }
          if (
            block.fieldGroupName ===
            'Template_PlatformSingle_Platformsingle_Post_TextCube'
          ) {
            return (
              <PostTextCube key={i} colorClass={colorClass} content={block} />
            )
          }
          if (
            block.fieldGroupName ===
            'Template_PlatformSingle_Platformsingle_Post_IconColumns'
          ) {
            return (
              <PostIconColumns
                key={i}
                colorClass={colorClass}
                content={block}
              />
            )
          }
          if (
            block.fieldGroupName ===
            'Template_PlatformSingle_Platformsingle_Post_BrandText'
          ) {
            return (
              <PostBrandText key={i} colorClass={colorClass} content={block} />
            )
          }
        })}
    </div>
  )
}

const PostTextFull = ({ content, colorClass }) => {
  const { title, body } = content
  return (
    <div className={colorClass}>
      <div className='Description'>
        <h1 className='title'>{title}</h1>
        <div className='MainText' dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  )
}

const PostTextColumns = ({ content, colorClass }) => {
  const { title, body } = content
  return (
    <div className={colorClass}>
      <div className={`Sec${capitalize(colorClass)}`}>
        <div className='colOne'>
          <h2 className='Sec2title'>{title}</h2>
        </div>
        <div className='colTwo'>
          <div className='SecText' dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  )
}

const PostTextCube = ({ content, colorClass }) => {
  const { title, body } = content
  return (
    <div className={colorClass}>
      <div className={`Sec${capitalize(colorClass)}`}>
        <div className='colOne'>
          <h2 className='SecIMGtitle'>{title}</h2>
          <div className='SecText' dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <div className='colCube'>
          <StaticImage className='cube' src='../images/Hypercube.png' alt='' />
        </div>
      </div>
    </div>
  )
}

const PostIconColumns = ({ content, colorClass }) => {
  const { columns } = content
  return (
    <div className={colorClass}>
      <div className={`Sec${capitalize(colorClass)}Icons`}>
        {columns.map((column, i) => (
          <div key={i} className={i === 0 ? 'colOne' : 'colTwo'}>
            {column.icon && (
              <ImageWrap
                image={column.icon}
                className='icon image-width-auto'
              />
            )}
            <h3
              className='Icontitle'
              dangerouslySetInnerHTML={{ __html: column.title }}
            />
            <p>
              <img
                className='blueLine'
                src='https://130pi83dceowsfvgo3o0ic4e-wpengine.netdna-ssl.com/wp-content/uploads/2021/04/BlueLine.svg'
                alt='blue line'
              />
            </p>
            <div
              className='SecText'
              dangerouslySetInnerHTML={{ __html: column.body }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const PostBrandText = ({ content, colorClass }) => {
  const { icon, pretitle, title, body } = content
  return (
    <div className={colorClass}>
      <div className={`Sec${capitalize(colorClass)}`}>
        <div className='colOne'>
          {icon && <ImageWrap image={icon} className='gps' />}
          {/* <img
            className='gps'
            src='https://130pi83dceowsfvgo3o0ic4e-wpengine.netdna-ssl.com/wp-content/uploads/2021/04/GPS_Enterprise_Square_RGB.png'
            alt='GPS-E'
          /> */}
          <p className='title-copy-block-50-50__pretitle'>{pretitle}</p>
          <h2 className='Sec2title'>{title}</h2>
        </div>
        <div className='colTwo'>
          <div className='SecText' dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  )
}
